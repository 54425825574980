<template>
    <div>
        <v-card flat>
            <v-alert dense outlined type="error" v-if="alertError">
                No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
            </v-alert>                                                                
            <v-toolbar-title class="TituloMateriaprima">Materiales o Insumos</v-toolbar-title>
            <div class="marginSelect">
                <v-row>
                    <v-col cols="12" lg="4" md="4">
                        <v-select v-model="sucursal" :items="sucursalesAsignadas" menu-props="auto"
                            label="Sucursal"
                            prepend-icon="mdi-city"
                            item-text="nombre"
                            item-value="sucursalId"
                            @change="seleccionarSucursal()"
                        ></v-select>
                    </v-col>    
                    <v-col cols="12" lg="4" md="4">
                        <v-select :disabled="!sucursal"
                            v-model="bodega"
                            :items="sucursalBodegas"
                            menu-props="auto"
                            label="Bodega"
                            prepend-icon="mdi-warehouse"
                            item-text="nombre"
                            item-value="bodegaId"
                            @change="seleccionarBodega()"
                        ></v-select>
                    </v-col> 
                     <v-col>
                        <v-btn v-if="bodega > 0" icon fab dark small color="primary" @click="ResetBodega()">
                            <v-icon dark>fas fa-backspace</v-icon>
                        </v-btn>
                    </v-col>
                </v-row> 
            </div>            
            <v-toolbar flat title>
                <v-text-field class="pr-4"
                    label="Buscar Materia Prima"
                    v-model="buscarMateriaPrima"
                    prepend-icon="mdi-magnify"
                    single-line
                    hide-details                    
                ></v-text-field>
                <v-spacer></v-spacer>
                <!-- boton para crear una nueva Materia Prima -->
                <v-tooltip bottom color="#0019d8" v-if="permisos.crear">
                    <template v-slot:activator="{ on }">
                            <v-btn :disabled="activeActions" color="#0019d8" outlined dark @click="AbrirDialogoCrearMaterialConstruccion()"
                                v-on="on" class="TamañoLetraBoton"
                            > 
                                <v-icon left>fas fa-plus</v-icon> Crear
                            </v-btn>
                    </template>
                    <span>Registrar Material o Insumo</span>
                </v-tooltip>    
                <!-- boton para actualizar tabla de herramientas -->
                <v-tooltip bottom color="#0019d8"  >
                    <template v-slot:activator="{ on }">
                            <v-btn color="#0019d8" outlined dark @click="ObtenerMateriaPrima()"
                                v-on="on" class="TamañoLetraBoton mx-2"  :disabled="activeActions"
                            > 
                                <v-icon >fas fa-sync-alt</v-icon> 
                            </v-btn>
                    </template>
                    <span>Actualizar</span>
                </v-tooltip>                        
                <!-- <BotonActualizarTala @Actualizartabla="ObtenerMateriaPrima"/> -->
            </v-toolbar>   
            <v-data-table
                :headers="EncabezadoTabla"
                :items="ArrayMaterialConstruccion"
                item-key="nombre"
                :search="buscarMateriaPrima"                
                class="elevation-1"                
                :footer-props="{itemsPerPageText: 'Filas por página'}" 
                :loading="loading"
                loading-text="Cargando Datos"
            >
                <template v-slot:item.action="{ item }">
                    <FiltrosBusquedaTimeLine :DatosRegistro="item" />
                    <!-- <v-tooltip left color="#000000">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" dark fab color="#000000" v-on="on" @click="AbrirModalTimeLine(item)">
                                <v-icon small>fas fa-eye</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Ver Movimientos de Materiales o Insumos</span>
                    </v-tooltip>                                                     -->
                    <v-tooltip left color="#01abe6">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#01abe6" v-on="on" @click="AbrirDialogoMovimientoCompra(item)" v-if="permisos.crear">
                                <v-icon small>fas fa-plus-square</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Realizar Movimiento</span>
                    </v-tooltip>           
                    <!--
                    <v-tooltip left color="#0019d8">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#0019d8" v-on="on" @click="AbrirDialogoTransferirMaterialInsumoASucursal(item)" v-if="permisos.crear">
                                <v-icon small>fas fa-exchange-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Transferir a Sucursales</span>
                    </v-tooltip> 
                    -->                                                                                                                        
                    <v-tooltip left color="#d69b01">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#d69b01" v-on="on" @click="AbrirDialogoEditarMaterialConstruccion(item)">
                                <v-icon small>fas fa-edit</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Editar</span>
                    </v-tooltip>                                              
                    <v-tooltip left color="#D80000" v-if="permisos.eliminar">
                        <template v-slot:activator="{ on }">
                            <v-btn small class="ml-2" icon outlined color="#D80000" v-on="on" @click="AbrirDialogoMensajeEliminar(item)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>                        
                        </template>
                        <span class="fontButton">Eliminar Material o Insumo</span>
                    </v-tooltip>                                              
                </template>
                <template v-slot:no-results>
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo">
                        Material o Insumo  no existe
                    </v-alert>                        
                </template>            
                <template v-slot:no-data v-if="ArrayMaterialConstruccion.length === 0">
                    <v-alert type="warning" prominent dense outlined border="left" class="text-xs-center mx-10 mt-2 titulo" v-if="!alertError">
                        No existen materiales o insumos registradas
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="alertError">
                        No fue posible la comunicación con <strong>API SERVICE</strong> ponganse en contacto con el administrador si este mensaje persiste.
                    </v-alert>                                                            
                </template>
            </v-data-table>
        </v-card>   
        <!-- mostramos el circular progress cuando carguen los datos al mostrar los incidentes de un vehiculo -->
        <v-overlay :value="EstadoCircularProgress">
            <v-progress-circular indeterminate :size="80" :width="10" color="#ffffff"></v-progress-circular> Cargando datos...
        </v-overlay>
        <MensajeEliminar 
            :DialogoMensajeEliminar="DialogoMensajeEliminar" @CerrarDialogoMensajeEliminar="CerrarDialogoMensajeEliminar"
            :tituloMensajeEliminar="tituloMensajeEliminar" @Eliminar="EliminarMateriaPrima"
            :loadingEliminarHerramientaMateriaPrima="loadingEliminarMateriaPrima" :alertError="alertError"
        />        
        <CrearMaterialConstruccion 
            :ArrayCatalogoMateriaPrima="ArrayCatalogoMateriaPrima" :bodega="bodega"
            @CerrarDialogoCrearMaterialConstruccion="CerrarDialogoCrearMaterialConstruccion" :esNuevo="esNuevo"
            :DialogoCrearMaterialConstruccion="DialogoCrearMaterialConstruccion" :TituloDialogo="TituloDialogo"
            @ActualizarTabla="ObtenerMateriaPrima" :ObjectEditarMaterialConstruccion="ObjectEditarMaterialConstruccion"
            :validarfechaobtenida="validarfecha" :EstadoPermisoEditar="EstadoPermisoEditar"
        />
        <RegistrarMovimientoCompraSalida 
            :ModalMovimientoDeCompra="ModalMovimientoDeCompra" @CerrarDialogoMovimientoCompra="CerrarDialogoMovimientoCompra"
            :TituloDialogo="TituloDialogo" :esNuevo="esNuevo" :DatosMaterialInsumo="DatosMaterialInsumo"
            :EstadoPermisoEditar="EstadoPermisoEditar" @ActualizarTabla="ObtenerMateriaPrima"        
        />        
        <TransferirMaterialInsumoASucursales 
            :ModalTransferirMaterialInsumoSucursal="ModalTransferirMaterialInsumoSucursal" @CerrarDialogoTransferirMaterialInsumoASucursal="CerrarDialogoTransferirMaterialInsumoASucursal"
            :DatosMaterialInsumos="DatosMaterialInsumos" :EstadoPermisoEditar="EstadoPermisoEditar" @ActualizarTabla="ObtenerMateriaPrima"
            :ArraySucursales="ArraySucursales"        
        />
    </div>
</template>

<script>
import CrearMaterialConstruccion from '../../../components/Almacen/MaterialConstruccion/CrearMaterialConstruccion'
import MensajeEliminar from '../../../components/MensajeEliminar'
import BotonActualizarTala from '../../BotonActualizarTabla'
import FiltrosBusquedaTimeLine from '../../../components/Almacen/MaterialConstruccion/FiltrosBusquedaTimeLine'
import RegistrarMovimientoCompraSalida from '../../../components/Almacen/MaterialConstruccion/RealizarMovimientoCompraSalida'
import TransferirMaterialInsumoASucursales from '../../../components/Almacen/MaterialConstruccion/TransferirMaterialOInsumoASucursal'


import { showMessageError,showMessageSuccess } from '../../../services/sweetAlert'
import { mapGetters, mapActions  } from 'vuex'

export default {
    data() {
        return {
            sucursalesAsignadas:[],
            sucursalBodegas:[],
            bodega:0,
            bodegaId:0,
            sucursal:-1,            
            // alerta cuando el api deja de funcionar
            alertError:false,
            // variable para mostrar un circular progress 
            EstadoCircularProgress: false,            

            loadingEliminarMateriaPrima: false,
            buscarMateriaPrima: '',
            EncabezadoTabla: [
                {text: 'Materia Prima', value: 'catalogoMateriales.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Marca', value: 'marcaMateriaPrima.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Unidad de Medida', value: 'unidadDeMedida.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Existencia', value: 'existencia', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Sucursal', value: 'bodega.sucursal.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Bodega', value: 'bodega.nombre', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 1', value: 'ubicacion1', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Ubicación 2', value: 'ubicacion2', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
                {text: 'Acciones', value: 'action', sortable: false, align: 'center', divider: 'true', class: 'TamañoLetraBoton'},
            ],
            ArrayMaterialConstruccion: [],
            ObjectEditarMaterialConstruccion: {},
            loading: false,

            DialogoCrearMaterialConstruccion: false,
            TituloDialogo: '',
            esNuevo: true,
            validarfecha: '',
            DialogoMensajeEliminar: false,
            tituloMensajeEliminar: '',            
            IdMateriaPrima: '',
            //variables para los permisos del colaborador
            namespace:'oidcStore/',    
            permisos:{
                crear:false,
                editar:false,
                eliminar:false
            },         
            EstadoPermisoEditar: true,
            //variables para movimiento de compra
            ModalMovimientoDeCompra: false,
            DatosMaterialInsumo:{},      
            // variables para transferir material o insumo a sucursales
            ModalTransferirMaterialInsumoSucursal: false,
            ArraySucursales:[],        
            // para ver movimientos
            DatosMaterialInsumos:{},

            activeActions:true,
            ArrayCatalogoMateriaPrima:[],
        }
    },
    components: {
        CrearMaterialConstruccion,
        MensajeEliminar,
        BotonActualizarTala,
        FiltrosBusquedaTimeLine,
        RegistrarMovimientoCompraSalida,
        TransferirMaterialInsumoASucursales,
    },
    computed: {
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
            'oidcUser'
        ]),      
    },    
    created() {
        this.obtenerSucursalesAsignadas()
        this.ObtenerPermisosSubmenuUsuario()
    },
    methods: {
        ResetBodega(){
            this.bodega = 0
            this.ObtenerMateriaPrima()
        },
        seleccionarBodega(){
            //si ha seleccionado una sucursal y proyecto
            if(this.sucursal != '' && this.bodega != ''){
                this.activeActions=false
                this.bodegaId=this.bodega
                this.ObtenerMateriaPrima()
            }
        },
        seleccionarSucursal(){
            this.bodega = 0
            this.ArrayMaterialConstruccion=[]
            this.activeActions=false
            this.sucursalesAsignadas.forEach(suc => {
                if(suc.sucursalId == this.sucursal){
                    this.sucursalBodegas = suc.bodegas
                }
            })
            this.ObtenerMateriaPrima()
        },
        async obtenerSucursalesAsignadas(){
            const response  = await this.$store.dispatch('misSucursales')
            if(response === undefined){
                showMessageError('No fue posible obtener las sucursales asignadas')
                return
            }else if(response.status === 200){
                this.sucursalesAsignadas = response.data
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                    this.sucursalesAsignadas.push({nombre: 'Todas', sucursalId:0})
                }
            }
        },        
        // controlamos la excepcion, y mostramos una alerta cuando el api deja de funcionar
        ControllerException(){
            this.alertError=true
            this.loading=false
        },        

        // metodos para obtener los permisos del colaborador a este modulo
            ...mapActions('oidcStore', ['authenticateOidcSilent',
                    'signOutOidc', 'authenticateOidc']),        

            async ObtenerPermisosSubmenuUsuario(role){
            if (this.$store.getters[this.namespace + 'oidcIsAuthenticated']) {
                if(this.$store.getters['oidcStore/oidcUser'].role === 'Administrador'){
                        this.permisos.crear = true
                        this.permisos.editar = true
                        this.permisos.eliminar = true
                        return 0            
                }else{
                    //preguntar al backend por los permisos del submenu del usuario
                        var url = 'materialdeconstruccion'
                        const response  = await this.$store.dispatch('PermisosSubMenu', url)
                        if(typeof response === 'undefined'){
                            this.ControllerException()
                            return 0
                        }
                        if(response.status === 200){
                            this.alertError= false
                            this.permisos = response.data      
                            // console.log(this.permisos)   
                        }else if(response.status === 400){
                            showMessageError('Error 400','Petición mala')
                        }else if(response.status === 401){
                            showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                        }else if(response.status === 404){
                            showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                        }else if(response.status === 500){
                            showMessageError('Error 500','Error en el servidor')            
                        }
                        return 0
                }
            }
            },

        //metodos para crear material de construccion 
            async AbrirDialogoCrearMaterialConstruccion(){
                this.EstadoCircularProgress = true
                this.TituloDialogo="Crear "
                this.esNuevo = true
                if(this.permisos.crear) this.EstadoPermisoEditar = true

                const response = await this.$store.dispatch('ObtenerListadoMateriaPrimaCatalogo')
                if(response.status === 200){
                    this.ArrayCatalogoMateriaPrima = response.data
                    this.DialogoCrearMaterialConstruccion = true
                    this.EstadoCircularProgress = false
                }else{
                    this.ControlladorExcepciones(response)
                }
            },
            CerrarDialogoCrearMaterialConstruccion(){
                this.DialogoCrearMaterialConstruccion = false
            },
        //metodos para editar material de construccion
            async AbrirDialogoEditarMaterialConstruccion(item){
                this.EstadoCircularProgress = true
                const response = await this.$store.dispatch('ObtenerMateriaPrimaById', item.idMateriaPrima)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ObjectEditarMaterialConstruccion = response.data
                    this.ObjectEditarMaterialConstruccion.catalogoMaterialesId=response.data.catalogoMateriales.catalogoMaterialesId
                    this.ObjectEditarMaterialConstruccion.bodegaId=this.bodegaId
                    this.ArrayCatalogoMateriaPrima=[]
                    this.ArrayCatalogoMateriaPrima.push(response.data.catalogoMateriales)
                    this.validarfecha = response.data.fechaCompra
                    this.TituloDialogo="Editar "
                    this.EstadoCircularProgress = false
                    this.esNuevo = false
                    if(this.permisos.editar){
                        this.EstadoPermisoEditar = true
                    }else{
                        this.EstadoPermisoEditar = false
                    }                    
                    this.DialogoCrearMaterialConstruccion = true
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.EstadoCircularProgress = false
                }
            },        
        //obtenemos los materiales de construccion para mostrarlo en la tabla
            async ObtenerMateriaPrima(){
                this.loading = true
                var data = {bodega:this.bodega, sucursal:this.sucursal}
                const response = await this.$store.dispatch('ObtenerMaterialConstruccion', data)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ArrayMaterialConstruccion =  response.data
                    this.loading = false
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loading = false
                }
            },
        //eliminar materia prima
            AbrirDialogoMensajeEliminar(item){
                this.tituloMensajeEliminar = item.nombre
                this.IdMateriaPrima = item.idMateriaPrima
                this.DialogoMensajeEliminar = true
            },

            CerrarDialogoMensajeEliminar(){
                this.DialogoMensajeEliminar = false                
            },

            async EliminarMateriaPrima() {
                this.loadingEliminarMateriaPrima = true
                const response = await this.$store.dispatch('EliminarMateriaPrimaById', this.IdMateriaPrima)
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.loadingEliminarMateriaPrima = false
                    return 0
                }                 
                if(response.status === 200){
                    this.alertError= false
                    this.ObtenerMateriaPrima()
                    showMessageSuccess('¡BIEN!', 'Materia Prima eliminado correctamente')
                    this.loadingEliminarMateriaPrima = false
                    this.CerrarDialogoMensajeEliminar()
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.loadingEliminarMateriaPrima = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.loadingEliminarMateriaPrima = false
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.loadingEliminarMateriaPrima = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')            
                    this.loadingEliminarMateriaPrima = false
                }
            },
        // metodos para movimiento de compra de material o insumo
            AbrirDialogoMovimientoCompra(datos){
                this.TituloDialogo = "Compra"
                this.esNuevo = true
                this.ModalMovimientoDeCompra = true
                this.DatosMaterialInsumo = datos
            },

            CerrarDialogoMovimientoCompra(){
                this.ModalMovimientoDeCompra = false
            },            
        // metodos para transferir material o insumo a sucursales
        //obtenemos todas las sucursales
            async AbrirDialogoTransferirMaterialInsumoASucursal(datos){
                this.EstadoCircularProgress = true
                // console.log('datos: ', datos)
                const response = await this.$store.dispatch('getAllSucursales')
                if(response.status === 200){
                    this.DatosMaterialInsumos = datos
                    this.ArraySucursales = response.data
                    this.EstadoCircularProgress = false
                    this.ModalTransferirMaterialInsumoSucursal = true
                }else{
                    this.ControlladorExcepciones(response)
                }
            },

            CerrarDialogoTransferirMaterialInsumoASucursal(){
                this.ModalTransferirMaterialInsumoSucursal = false
            },            
            // controlamos todas las excepciones http
            ControlladorExcepciones(){
                this.loading = true
                if(typeof response === 'undefined'){
                    this.ControllerException()
                    this.EstadoCircularProgress = false
                    return 0
                }else if(response.status === 400){
                    showMessageError('Error 400','Petición mala')
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 401){
                    showMessageError('No Autorizado!','Su usuario no tiene permisos para este módulo')
                    this.EstadoCircularProgress = false
                    this.loading = false                      
                }else if(response.status === 404){
                    showMessageError('Error 404','El servidor no encontro el recurso solicitado')            
                    this.EstadoCircularProgress = false
                    this.loading = false
                }else if(response.status === 500){
                    showMessageError('Error 500','Error en el servidor')    
                    this.EstadoCircularProgress = false        
                    this.loading = false
                }                
            }            
    },
}
</script>
<style>
    .TituloMateriaprima {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-family: 'PT Serif';
        font-size: 25px;         
    }
</style>